import { ref, computed } from 'vue';
import { getInvestments, getTenures, getBanks } from '../requests';
import { format } from 'date-fns';
import { TenureProp, InvestmentProp } from '@/types/Investment';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { AxiosError, AxiosResponse } from 'axios';

export default function investmentModule() {
  const { dispatch, commit, state } = useStore();
  const { push } = useRouter();

  const banks = ref<any>([]);
  const tenures = ref<TenureProp[]>([]);
  const setLoading = ref<boolean>(false);

  const loading = computed(() => state.utils.loading);
  const investments = computed(() => state.investment?.investments);

  const getAllInvestment = async (page = 1): Promise<any> => {
    commit('utils/setLoading', true);
    await getInvestments(page)
      .then((response: AxiosResponse) => {
        dispatch('investment/setInvestment', response?.data.data);
      })
      .catch((error: AxiosError) => console.log(error))
      .finally(() => commit('utils/setLoading', false));
  };

  const getAllTenures = async (): Promise<any> => {
    await getTenures()
      .then((response: AxiosResponse) => {
        tenures.value = response?.data;
        dispatch('utils/setTenures', response?.data);
      })
      .catch((error: AxiosError) => console.log(error));
  };

  const allBanks = async (): Promise<any> => {
    await getBanks()
      .then((response: AxiosResponse) => {
        banks.value = response?.data;
        dispatch('utils/setBanks', response?.data);
      })
      .catch((error: AxiosError) => console.log(error));
  };

  const bankOptions = computed(() =>
    banks.value?.map((bank: any) => {
      return {
        title: bank?.name,
        value: bank?.code,
      };
    }),
  );

  const activeInvestments = computed(() => {
    return investments.value
      ?.filter((investment: InvestmentProp) => investment?.status === 'running')
      ?.splice(0, 2);
  });

  const investmentStatus = (investment: InvestmentProp) => {
    let status, bgColor;

    switch (investment?.status) {
      case 'closed':
        status = 'Matured';
        bgColor = 'invest-green';
        break;
      case 'awaiting withdrawal':
        status = 'Confirming Withdrawal';
        bgColor = 'invest-lightblue';
        break;
      case 'withdraw requested':
        status = 'Withdraw Requested';
        bgColor = 'invest-lightblue';
        break;
      case 'approved':
        status = 'Withdrawn';
        bgColor = 'invest-darkgray';
        break;
      case 'liquidated':
        status = 'Liquidated';
        bgColor = 'invest-darkgray';
        break;
      case 'running':
        status = `Matures: ${format(
          new Date(investment?.closing_at as any),
          'do MMMM, yyyy',
        )}`;
        bgColor = 'invest-purple';
        break;
      case 'awaiting approval':
      default:
        status = 'Awaiting Payment';
        bgColor = 'invest-orange';
        break;
    }

    return {
      status,
      bgColor,
    };
  };

  const investmentDetails = (id: string | number): void => {
    push({ name: 'Investment-Details', params: { id: id } });
  };

  return {
    getAllInvestment,
    investments,
    getAllTenures,
    allBanks,
    banks,
    bankOptions,
    tenures,
    loading,
    activeInvestments,
    investmentStatus,
    investmentDetails,
    setLoading,
  };
}
